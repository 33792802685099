






























































import { PropType, computed, defineComponent, onMounted, onUnmounted, ref } from '@nuxtjs/composition-api';
import { SfCarousel, SfLoader, SfSection } from '@storefront-ui/vue';
import SfProductCard from '~/components/JetUi/organisms/Product/SfProductCard.vue';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import { useImage, useProduct } from '~/composables';
import { useAddToCart } from '~/helpers/cart/addToCart';
import { ProductStockStatus, SortEnum } from '~/modules/GraphQL/types';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import type { Product } from '~/modules/catalog/product/types';
import useWishlist from '~/modules/wishlist/composables/useWishlist';

export default defineComponent({
  name: 'ProductShowcase',
  components: {
    SfSection,
    SfLoader,
    SfCarousel,
    SfProductCard,
    SkeletonLoader
  },
  props: {
    settings: {
      default: () => ({
        type: 'carousel',
        rewind: true,
        perView: 6,
        autoplay: 9000,
        hoverpause: true,
        gap: 30,
        breakpoints: {
          1920: {
            perView: 6,
            gap: 20,
            peek: {
              before: 0,
              after: 50,
            },
          },
          1600: {
            perView: 5,
            gap: 18,
            peek: {
              before: 0,
              after: 50,
            },
          },
          1400: {
            perView: 4,
            gap: 16,
            peek: {
              before: 0,
              after: 50,
            },
          },
          1200: {
            perView: 3,
            gap: 15,
            peek: {
              before: 0,
              after: 50,
            },
          },
          992: {
            perView: 3,
            gap: 12,
            peek: {
              before: 0,
              after: 50,
            },
          },
          768: {
            perView: 3,
            gap: 8,
            peek: {
              before: 0,
              after: 15,
            },
          },
          725: {
            perView: 2,
            gap: 8,
            peek: {
              before: 0,
              after: 15,
            },
          },
          505: {
            perView: 1,
            peek: {
              before: 50,
              after: 50,
            },
          },
          360: {
            perView: 1,
            peek: {
              before: 30,
              after: 30,
            },
          },
        }
      }),
    },
    buttonText: {
      type: String,
      default: 'Ver Todos',
    },
    link: {
      type: String,
      default: '/',
    },
    title: {
      type: String,
      default: 'Produtos em Destaque',
    },
    category: {
      type: String,
      default: '',
    },
    skus: {
      type: Array,
      default: () => []
    },
    type: {
      default: 'category', // 'skus'
    },
    isCarousel: {
      default: false,
      type: String
    },
    sort: {
      default: 'relevance', // 'name', 'position', 'price', 'relevance'
    },
    sortDirection: {
      type: String,
      default: 'DESC', // ASC
    },
    amount: {
      default: 2,
      type: String

    },
    product: {
      type: Array as PropType<Product[]>,
    },
    categoryId: {
      type: String,
      default: '',
    },
    perView: {
      type: Number,
      required: false,
    }
  },
  emits: ['click:wishlist'],
  setup(props) {
    const qty = ref(1);
    const { getProductList, loading, getProductPath } = useProduct();
    const { isInWishlist, addOrRemoveItem } = useWishlist();
    const { addItemToCart, isInCart } = useAddToCart();
    const products = ref([]);
    const mappedProducts = computed(() => products.value.map((product) => ({
      ...product,
      isInWishlist: isInWishlist({ product }),
    })));

    const isInStock = (product) => {
      return product.stock_status === ProductStockStatus.InStock;
    }

    const addItemToWishlist = async (product) => {
      await addOrRemoveItem({ product });
    };

    const { getMagentoImage, imageSizes } = useImage();
    const searchTerm = props.type === 'category' ? props.category : '';
    const filtersList = computed(() => props.category.split(',') ?? props.skus);
    const showcaseSettings = ref(props.settings);
    const loadingProducts = ref(6);
    const shouldShowProduct = ref(false);
    const isClient = process.client;
    const windowWidth = ref(0);

    const transformStringIntoArray = (input: string) => {
      return input.split(',').map(item => item.trim());
    }

    const shuffleCategoryIds = (categoryIds: string[]) => {
      return shuffleArray(categoryIds);
    }

    function shuffleArray(array: Array<any>) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    }

    onMounted(async () => {
      window.addEventListener('resize', handleResize);

      if (isClient) {
        handleResize();
        for (const breakpoint in props.settings.breakpoints) {
          if (window.innerWidth <= parseInt(breakpoint)) {
            loadingProducts.value = props.settings.breakpoints[breakpoint].perView;
            break;
          };
        };
      };

      const filter = props.category
        ? { category: props.category }
        : props.categoryId
          ? { categoryId: shuffleCategoryIds(transformStringIntoArray(props.categoryId)) }
          : props.skus.length
            ? { sku:  props.skus }
            : {};


      const sortType = props.sort;
      const sortDirection = props.sortDirection === 'DESC' ? SortEnum.Desc : SortEnum.Asc;

      const productList = await getProductList({
        search: searchTerm,
        pageSize: parseInt(props.amount),
        currentPage: 1,
        sort: { [sortType]: sortDirection },
        ...filter,
      });

      if (productList?.items?.length) {
        const productsWithTypename = productList.items.map(product => ({
          ...product,
          typename: 'SimpleProduct',
        }));

        products.value = shuffleArray(productsWithTypename);
      };
      shouldShowProduct.value = true;
    });


    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    const shouldShowCarousel = computed(() => {
      return windowWidth.value <= 1200 || props.isCarousel;
    });

    if (props.perView) {
      showcaseSettings.value.perView = props.perView;
      showcaseSettings.value.breakpoints[1920].perView = props.perView;
      showcaseSettings.value.breakpoints[1600].perView = props.perView;
      showcaseSettings.value.breakpoints[1400].perView = props.perView;
      showcaseSettings.value.breakpoints[1200].perView = props.perView;
    }

    const getFlexStyle = () => {
      const breakpoints = props.settings.breakpoints;
      const width = windowWidth.value;
      let perView = 1;
      let gap = 25;

      for (const breakpoint in breakpoints) {
        if (width <= parseInt(breakpoint)) {
          perView = breakpoints[breakpoint].perView;
          gap = breakpoints[breakpoint].gap;
          break;
        }
      }

      return {
        display: 'flex',
        flexWrap: 'wrap',
        gap: `${gap}px`,
        justifyContent: perView === 1 ? 'center' : 'flex-start',
      };
    };

    const flexStyle = ref(getFlexStyle());

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize);
    });

    const handleQtyUpdate = (newQty: number) => {
      qty.value = newQty;
    };

    return {
      addItemToCart,
      addItemToWishlist,
      isInCart,
      isInWishlist,
      loading,
      mappedProducts,
      productGetters,
      getMagentoImage,
      imageSizes,
      getProductPath,
      flexStyle,
      qty,
      handleQtyUpdate,
      shouldShowCarousel,
      isInStock,
      filtersList,
      loadingProducts,
      shouldShowProduct,
      showcaseSettings
    };
  },
});
