import axios from 'axios';
import { defineStore } from 'pinia';

export const useSuggestStore = defineStore('suggest', {
  state: () => ({
    productsSku: { recommendations: [], user: '' } ,
  }),
  getters: {
    getProductsSku(state) {
      return state.productsSku || { recommendations: [], user: '' };
    }
  },

  actions: {
    async updateProductsSku(email) {
      try {
        const response = await axios.get('/suggest/recomendar?user=' + email);
        this.productsSku = response.data;
      } catch (error) {
        console.error('Erro ao atualizar os produtos:', error);
      }
    }
    
  },
});