






















import { computed, onMounted, ref } from '@nuxtjs/composition-api';
import { SfCarousel } from '@storefront-ui/vue';

interface Brand {
  title: string;
  imagePath: string;
}

export default {
  components: {
    SfCarousel,
  },
  props: {
    settings: {
      default: () => ({
        type: 'carousel',
        rewind: false,
        slidePerPage: false,
        hoverPause: true,
        perView: 5,
        autoplay: 3000,
        gap: 35,
        breakpoints: {
          1700: {
            perView: 4,
            gap: 15,
          },
          1024: {
            perView: 3,
          },
          768: {
            perView: 2,
          },
        },
      }),
    },
  },
  setup(props, { root }) {
    const windowWidth = ref(1920);
    const randomBrands = ref<Brand[]>([]);

    const isMobile = computed(() => windowWidth.value < 1024);

    const onResize = () => {
      windowWidth.value = window.innerWidth;
    };

    const shuffleArray = (array: Brand[]): Brand[] => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };

    const loadRandomBrands = () => {
      const requireSvg = (require as any).context('~/static/brands/', false, /\.svg$/);
      const svgs = requireSvg.keys().map((fileName: string) => {
        const cleanFileName = fileName.replace('./', '').replace('.svg', '').replace(/\s+/g, '-').toLowerCase();

        return {
          title: cleanFileName,
          imagePath: `/brands/${fileName}`,
        };
      });
      randomBrands.value = shuffleArray(svgs).slice(0, 5);
    };

    const searchProductsByBrand = (brandName: string) => {
      console.log("Clicou na marca:", brandName);
      root.$router.push({ path: `/search/${brandName}` });
    };

    onMounted(() => {
      window.addEventListener('resize', onResize);
      loadRandomBrands();
    });

    return {
      windowWidth,
      randomBrands,
      isMobile,
      settings: props.settings,
      searchProductsByBrand,
    };
  },
};
