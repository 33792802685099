<template>
  <div class="sf-carousel">
    <div
      ref="controls"
      class="sf-carousel__controls"
    >
      <SfArrow
        class="sf-arrow--transparent"
        aria-label="previous"
        @click="goToPrevious"
      />
      <SfArrow
        class="sf-arrow--right sf-arrow--transparent"
        aria-label="next"
        @click="goToNext"
      />
    </div>
    <div
      ref="glideElement"
      :class="['sf-carousel__wrapper', { 'sf-carousel-initialized': isInitialized }]"
    >
      <div class="glide">
        <div
          class="glide__track"
          data-glide-el="track"
        >
          <ul class="glide__slides sf-carousel__slides">
            <slot />
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Glide from '@glidejs/glide';
import {
computed,
defineComponent,
nextTick,
onBeforeUnmount,
onMounted,
ref,
watch,
} from '@nuxtjs/composition-api';
import { SfArrow } from '@storefront-ui/vue';

export default defineComponent({
  name: 'JetBanner',
  components: {
    SfArrow,
  },
  props: {
    settings: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const controls = ref(null);
    const glideElement = ref(null);
    const glideInstance = ref(null);
    const activeIndex = ref(0);
    const isInitialized = ref(false);

    const defaultSettings = {
      type: 'carousel',
      rewind: true,
      perView: 1,
      gap: 0,
      autoplay: 5000,
      hoverpause: true,
    };

    const mergedOptions = computed(() => ({
      ...defaultSettings,
      ...props.settings,
    }));

    const preloadImages = async () => {
      const images = glideElement.value?.querySelectorAll('img') || [];
      return Promise.all(
        [...images].map(
          (img) => new Promise((resolve) => {
            if (img.complete && img.naturalWidth > 0) {
              resolve();
            } else {
              img.addEventListener('load', resolve);
              img.onerror = resolve;
            }
          }),
        ),
      );
    };

    const initializeGlide = () => {
      if (glideInstance.value) return;

      const instance = new Glide(glideElement.value, mergedOptions.value);
      instance.mount();

      instance.on('run', () => {
        activeIndex.value = instance.index;
      });

      glideInstance.value = instance;
      isInitialized.value = true;
    };

    const destroyGlide = () => {
      if (glideInstance.value) {
        glideInstance.value.destroy();
        glideInstance.value = null;
        isInitialized.value = false;
      }
    };

    const goToPrevious = () => {
      if (glideInstance.value) {
        glideInstance.value.go('<');
      }
    };

    const goToNext = () => {
      if (glideInstance.value) {
        glideInstance.value.go('>');
      }
    };

    onMounted(async () => {
      await preloadImages();
      emit('initialized');
      nextTick(() => {
        initializeGlide();
      });
    });

    onBeforeUnmount(() => {
      destroyGlide();
    });

    watch(() => props.settings, () => {
      if (glideInstance.value) {
        glideInstance.value.update(mergedOptions.value);
      }
    });

    return {
      controls,
      glideElement,
      activeIndex,
      goToPrevious,
      goToNext,
      isInitialized,
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep .sf-carousel {
    &__wrapper {
        max-width: 100%;
        overflow: hidden;
        visibility: hidden;
    }

    &__wrapper.sf-carousel-initialized {
        visibility: visible;
    }

    &__slides {
        display: flex;
        align-items: center;
    }

    & img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    &__controls {
        position: absolute;
        top: 50%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transform: translateY(-50%);
        z-index: 1;
        pointer-events: none;
    }

    &__controls > .sf-arrow {
        pointer-events: auto;
        cursor: pointer;
        margin: 0 15px;
    }
}
</style>
