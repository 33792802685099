




























































































import {
  defineComponent,
  onMounted,
  ref,
  watchEffect
} from '@nuxtjs/composition-api';

import { computed, nextTick } from '@nuxtjs/composition-api';
import { CacheTagPrefix, useCache } from '@vue-storefront/cache';
import Bigbrands from '~/components/JetUi/molecules/bigbrands/bigbrands.vue';
import BannerCarousel from '~/components/JetUi/organisms/Banner/BannerCarousel.vue';
import BenefitsDisplay from '~/components/JetUi/organisms/Benefits/BenefitsDisplay.vue';
import ProductShowcase from '~/components/JetUi/organisms/Product/ProductShowcase.vue';
import { useUser } from '~/composables';
import BlogCarrousel from '~/server/blog/BlogCarrousel.vue';
import { useSuggestStore } from '~/stores/suggest';
declare const require: {
  context: (path: string, useSubdirectories?: boolean, regExp?: RegExp) => {
    keys: () => string[];
    (key: string): string;
  };
};
export default defineComponent({
  name: 'HomePage',
  components: {
    Bigbrands,
    ProductShowcase,
    BannerCarousel,
    BenefitsDisplay,
    BlogCarrousel
  },
  setup() {
    const { user } = useUser();
    const suggestSkus = computed(() => {
      return suggestStore?.productsSku?.recommendations?.length
        ? suggestStore.productsSku.recommendations.map(item => String(item.product))
        : [];
    });

    const suggestStore = useSuggestStore();
    const { addTags } = useCache();
    const loaded = ref(false);
    const banners = ref([]);
    const settings = {
      type: 'carousel',
      perView: 1,
      autoplay: 5000,
      hoverpause: true,
      gap: 0,
    };

    const loadCarouselBanners = async () => {
      const context = require.context('@/static/banners/carousel/desktop', false, /\.(webp|gif)$/);

      const [contextKeys, links] = await Promise.all([
        Promise.resolve(context.keys()),
        getBannerLinks()
      ]);

      const bannersMap = {};
      const today = new Date();

      contextKeys.forEach((fileName) => {
        const fileNameCleaned = fileName.replace('./', '');
        const filePath = `/banners/carousel/desktop/${fileNameCleaned}`;
        const [, id, expire] = fileNameCleaned.replace('.webp', '').split('_');

        const [day, month, year] = expire.split('-').map(Number);
        const expireDate = new Date(year, month - 1, day);

        if (expireDate < today) {
          changeBanner(fileName, 'desktop');
          changeBanner(fileName, 'mobile');
          return;
        }

        if (!bannersMap[id] || expireDate < bannersMap[id].expireDate) {
          bannersMap[id] = {
            id,
            expireDate,
            image: filePath,
            imageMobile: filePath.replace('desktop', 'mobile'),
            link: links[fileNameCleaned],
          };
        }
      });

      banners.value = Object.values(bannersMap);
    };

    const getBannerLinks = async () => {
      try {
        const response = await fetch('/banners.json');
        return response.json();
      } catch (error) {
        console.error('Error fetching banner links:', error);
        return {};
      }
    };

    const changeBanner = (fileName: string, type: string) => {
      fetch('/image/move', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          fileName: fileName.replace('./', ''),
          fromFolder: `banners/carousel/${type}`,
          toFolder: `banners/carousel/inactive/${type}`,
        }),
      });
    };

    onMounted(async () => {
      if (suggestStore?.productsSku?.recommendations?.length < 1 && user.value) {
        suggestStore.updateProductsSku(user.value.email);
      }
      addTags([{ prefix: CacheTagPrefix.View, value: 'home' }]);
      await loadCarouselBanners();
      setTimeout(() => {
        loaded.value = true;
      }, 100);
    });


    watchEffect(async () => {
      if (suggestStore?.productsSku?.recommendations?.length) {
        await nextTick();
      }

    });
    watchEffect(() => {
      if (user.value && user.value.email) {
        suggestStore.updateProductsSku(user.value.email);
      }
    });


    return {
      banners,
      settings,
      suggestSkus,
      loaded,
    };
  },
  head() {
    const pageTitle = 'Espacial Suprimentos';
    return {
      title: pageTitle.toString(),
    };
  },
});
